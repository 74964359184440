<template>
  <div class="ticketAddEdit">
    <el-dialog :title="dioTit" :visible.sync="isDio" :width="dioWidth" :close-on-click-modal="false" @close="closeDio('dioform')">
      <div class="contDio">
        <div class="stepDiv mb10" v-if="dioType!='add'">
          <div :class="['tipsIcon',{'fail':inform.ticketStatus=='5'}]" v-if="inform.ticketStatus=='4'||inform.ticketStatus=='5'"></div>
          <el-steps :space="200" :active="stepActive" align-center finish-status="success">
            <el-step title="工单生成">
              <div slot="description">
                <p>{{formatTime2(inform.createTime)}}</p>
                <p>管理员:{{inform.createUserName}}</p>
              </div>
            </el-step>
            <el-step>
              <div slot="title">{{inform.ticketStatus < 1?'未派单':'工单派发'}}</div>
              <div slot="description" v-if="inform.ticketStatus >= 1 && inform.ticketStatus < 6">
                <p>{{formatTime2(inform.dispatchTime)}}</p>
                <p>管理员:{{inform.createUserName}}</p>
                <p>处理人:{{inform.handlerName}}</p>
              </div>
            </el-step>
            <el-step>
              <div slot="title">{{inform.ticketStatus < 2?'待处理':inform.ticketStatus == 2?'处理中':'工单处理'}}</div>
              <div slot="description" v-if="inform.ticketStatus >= 2 && inform.ticketStatus < 6">
                <p v-if="inform.ticketStatus == 2">{{formatTime2(inform.progressTime)}}</p>
                <p v-if="inform.ticketStatus > 2">{{formatTime2(inform.progressTime)+'~'+formatTime2(inform.handleTime)}}</p>
                <p>处理人:{{inform.handlerName}}</p>
              </div>
            </el-step>
            <el-step>
              <div slot="title">{{inform.ticketStatus < 3?'待审核':inform.ticketStatus == 3?'审核中':'工单审核'}}</div>
              <div slot="description" v-if="inform.ticketStatus > 3 && inform.ticketStatus < 6">
                <p>{{formatTime2(inform.auditTime)}}</p>
                <p>审批人:{{inform.auditName}}</p>
                <p v-if="inform.ticketStatus==4">审核通过</p>
                <p v-if="inform.ticketStatus==5">审核不通过</p>
              </div>
            </el-step>
            <el-step>
              <div slot="title">{{inform.ticketStatus < 4 ?'未完成':inform.ticketStatus < 6 ?'已完成':'已取消'}}</div>
              <div slot="description" v-if="inform.ticketStatus > 3">
                <p v-if="inform.ticketStatus==4||inform.ticketStatus==5">{{formatTime2(inform.auditTime)}}</p>
                <p v-if="inform.ticketStatus==6">{{formatTime2(inform.cancelTime)}}</p>
              </div>
            </el-step>
          </el-steps>
        </div>
        <el-form ref="dioform" :rules="rules" :model="inform" label-width="120px" size="small">
          <el-row>
          <!-- <el-col :span="12">
          <el-form-item label="场所:" prop="homeId">
            <el-select v-model="inform.homeId" placeholder="请选择场所" @change="homeChange" :disabled="dioType!='add'">
              <el-option v-for="(item,index) in dicObj.homeList" :key="index" :label="item.homeName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          </el-col> -->
          <el-col :span="12">
          <el-form-item label="类型:" prop="ticketType">
            <el-select v-model="inform.ticketType" placeholder="类型" :disabled="dioType!='add'" v-if="dioType=='add'">
              <el-option v-for="(item,index) in typeData" :key="index" :label="item.dictName" :value="item.dictValue"></el-option>
            </el-select>
            <el-select v-model="inform.ticketType" placeholder="类型" :disabled="dioType!='add'" v-else>
              <el-option v-for="(item,index) in dicObj.typeData" :key="index" :label="item.dictName" :value="item.dictValue"></el-option>
            </el-select>
          </el-form-item>
          </el-col>
          <el-col :span="12">
          <el-form-item label="等级:" prop="ticketLevel">
            <el-select v-model="inform.ticketLevel" placeholder="等级" :disabled="dioType!='add'&&dioType!='edit'&&dioType!='reorder'">
              <el-option label="特急" value="1"></el-option>
              <el-option label="紧急" value="2"></el-option>
              <el-option label="较急" value="3"></el-option>
              <el-option label="一般" value="4"></el-option>
            </el-select>
          </el-form-item>
          </el-col>
          <el-col :span="12">
          <el-form-item label="处理人:" prop="handlerUser">
            <el-select v-model="inform.handlerUser" placeholder="处理人" :disabled="dioType!='add'&&dioType!='edit'&&dioType!='reorder'">
              <el-option label="暂不派单" value="" v-if="dioType=='edit'||dioType=='reorder'"></el-option>
              <el-option v-for="(item,index) in userList" :key="index" :label="item.name" :value="item.userId"></el-option>
            </el-select>
          </el-form-item>
          </el-col>
          </el-row>
          <template v-if="inform.ticketType=='4'">
          <el-form-item label="安装设备" prop="deviceInstallList">
            <div class="addBtn" v-if="dioType == 'add'">
              <el-button type="text" @click="addItem()"><i class="el-icon-circle-plus-outline"></i>添加</el-button>
            </div>
            <template v-if="inform.deviceInstallList.length>0">
            <el-table class="tableCont" :data="inform.deviceInstallList" stripe style="width:100%">
              <el-table-column label="设备类型">
                <template slot-scope="scope">
                  <el-select v-model="scope.row.deviceTypeName" placeholder="类型" :disabled="dioType!='add'">
                    <el-option v-for="(item,index) in typeList" :key="index" :label="item.deviceTypeName" :value="item.deviceTypeName"></el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column label="数量">
                <template slot-scope="scope">
                  <el-input-number v-model="scope.row.installNumber" :min="1" placeholder="数量" :disabled="dioType!='add'"></el-input-number>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="60" v-if="dioType=='add'">
                <template slot-scope="scope">
                  <el-button type="text" @click="removeItem(scope.$index)" class="error" title="移除" icon="el-icon-remove-outline"></el-button>
                </template>
              </el-table-column>
            </el-table>
            </template>
          </el-form-item>
          </template>
          <el-form-item label="备注:" prop="remark">
            <el-input type="textarea" :rows="5" v-model="inform.remark" placeholder="请输入备注" :disabled="dioType!='add'"></el-input>
          </el-form-item>
          <template v-if="dioType=='handle'||dioType=='detail'||dioType=='audit'||(dioType=='edit'&&inform.ticketStatus=='3')">
          <el-form-item label="处理方式:" prop="handleType">
            <el-radio-group v-model="inform.handleType" :disabled="dioType=='detail'||dioType=='audit'">
              <el-radio label="1">现场处理</el-radio>
              <el-radio label="2" v-if="inform.ticketType!='4'&&inform.ticketType!='5'">远程诊断</el-radio>
            </el-radio-group>
          </el-form-item>
          <template v-if="inform.ticketType!='4'&&inform.ticketType!='5'">
          <el-form-item label="告警详情">
            <el-table class="tableCont" :data="inform.alarmRespList" stripe style="width:100%">
              <el-table-column prop="homeName" label="场所" fixed/>
              <el-table-column prop="devName" label="告警设备"  fixed/>
              <el-table-column prop="alarmTypeName" label="告警类型" />
              <el-table-column prop="alarmCount" label="报警次数" />
              <el-table-column prop="content" label="内容" width="300"/>
              <el-table-column prop="createTime" label="时间" width="200"/>
            </el-table>
          </el-form-item>
          <el-form-item label="告警分析及原因:">
            <el-input type="textarea" :rows="1" v-model="inform.alarmAnalysis" :disabled="dioType=='detail'||dioType=='audit'" placeholder="请填写告警分析及原因"></el-input>
            <div class="imgDiv">
              <div class="item" v-for="(item,index) in inform.alarmAnalysisIconsShow" :key="index" v-if="inform.alarmAnalysisIconsShow.length>0">
                <el-image style="width: 58px; height: 58px" :src="item.url" fit="cover"></el-image>
                <div class="btn">
                  <i class="el-icon-zoom-in" @click="iconPreview(item.url)"></i>
                  <i class="el-icon-delete" @click="delIcon(index,item,'4')" v-if="dioType!='detail'&&dioType!='audit'"></i>
                </div>
              </div>
              <el-upload
                v-if="dioType!='detail'&&dioType!='audit'"
                class="uploader"
                action="#"
                ref="upload"
                multiple
                :show-file-list="false"
                list-type="picture-card"
                :http-request="httpRequest4"
                :before-upload="beforeUpload">
                <i class="el-icon-plus"></i>
              </el-upload>
            </div>
          </el-form-item>
          <el-form-item label="解决措施及建议:">
            <el-input type="textarea" :rows="1" v-model="inform.suggestion" :disabled="dioType=='detail'||dioType=='audit'" placeholder="请填写解决措施及建议"></el-input>
            <div class="imgDiv">
              <div class="item" v-for="(item,index) in inform.suggestionIconsShow" :key="index" v-if="inform.suggestionIconsShow.length>0">
                <el-image style="width: 58px; height: 58px" :src="item.url" fit="cover"></el-image>
                <div class="btn">
                  <i class="el-icon-zoom-in" @click="iconPreview(item.url)"></i>
                  <i class="el-icon-delete" @click="delIcon(index,item,'5')" v-if="dioType!='detail'&&dioType!='audit'"></i>
                </div>
              </div>
              <el-upload
                v-if="dioType!='detail'&&dioType!='audit'"
                class="uploader"
                action="#"
                ref="upload"
                multiple
                :show-file-list="false"
                list-type="picture-card"
                :http-request="httpRequest5"
                :before-upload="beforeUpload">
                <i class="el-icon-plus"></i>
              </el-upload>
            </div>
          </el-form-item>
          </template>

          <template v-if="inform.ticketType=='4'||inform.ticketType=='5'">
          <el-form-item :label="inform.ticketType=='4'?'安装前:':'处理前:'">
            <div class="imgDiv">
              <div class="item" v-for="(item,index) in inform.installBeforeIconsShow" :key="index" v-if="inform.installBeforeIconsShow.length>0">
                <el-image style="width: 58px; height: 58px" :src="item.url" fit="cover"></el-image>
                <div class="btn">
                  <i class="el-icon-zoom-in" @click="iconPreview(item.url)"></i>
                  <i class="el-icon-delete" @click="delIcon(index,item,'1')" v-if="dioType!='detail'&&dioType!='audit'"></i>
                </div>
              </div>
              <el-upload
                v-if="dioType!='detail'&&dioType!='audit'"
                class="uploader"
                action="#"
                ref="upload"
                multiple
                :show-file-list="false"
                list-type="picture-card"
                :http-request="httpRequest1"
                :before-upload="beforeUpload">
                <i class="el-icon-plus"></i>
              </el-upload>
            </div>
          </el-form-item>
          <el-form-item :label="inform.ticketType=='4'?'安装后:':'处理后:'">
            <div class="imgDiv">
              <div class="item" v-for="(item,index) in inform.installedIconsShow" :key="index" v-if="inform.installedIconsShow.length>0">
                <el-image style="width: 58px; height: 58px" :src="item.url" fit="cover"></el-image>
                <div class="btn">
                  <i class="el-icon-zoom-in" @click="iconPreview(item.url)"></i>
                  <i class="el-icon-delete" @click="delIcon(index,item,'2')" v-if="dioType!='detail'&&dioType!='audit'"></i>
                </div>
              </div>
              <el-upload
                v-if="dioType!='detail'&&dioType!='audit'"
                class="uploader"
                action="#"
                ref="upload"
                multiple
                :show-file-list="false"
                list-type="picture-card"
                :http-request="httpRequest2"
                :before-upload="beforeUpload">
                <i class="el-icon-plus"></i>
              </el-upload>
            </div>
          </el-form-item>
          </template>
          <el-form-item label="处理结果:">
            <el-input type="textarea" :rows="1" v-model="inform.finalResult" :disabled="dioType=='detail'||dioType=='audit'" placeholder="请填写处理结果"></el-input>
            <div class="imgDiv">
              <div class="item" v-for="(item,index) in inform.finalResultIconsShow" :key="index" v-if="inform.finalResultIconsShow.length>0">
                <el-image style="width: 58px; height: 58px" :src="item.url" fit="cover"></el-image>
                <div class="btn">
                  <i class="el-icon-zoom-in" @click="iconPreview(item.url)"></i>
                  <i class="el-icon-delete" @click="delIcon(index,item,'3')" v-if="dioType!='detail'&&dioType!='audit'"></i>
                </div>
              </div>
              <el-upload
                v-if="dioType!='detail'&&dioType!='audit'"
                class="uploader"
                action="#"
                ref="upload"
                multiple
                :show-file-list="false"
                list-type="picture-card"
                :http-request="httpRequest3"
                :before-upload="beforeUpload">
                <i class="el-icon-plus"></i>
              </el-upload>
            </div>
          </el-form-item>
          </template>
        </el-form>
      </div>
      <span slot="footer">
        <el-button @click="closeDio('dioform')" size="small">关闭</el-button>
        <el-button v-if="dioType=='handle'" type="primary" @click="checkForm('dioform','1')" size="small">暂存</el-button>
        <el-button v-if="dioType=='audit'" type="error" @click="checkForm('dioform','2')" size="small">不通过</el-button>
        <el-button v-if="dioType!='detail'" type="primary" @click="checkForm('dioform')" size="small">{{dioType=='audit'?'通过':'提交'}}</el-button>
      </span>
    </el-dialog>

    <el-dialog title="预览" :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>
<script>
import { apiUrl,globalStr } from '@/assets/js/api'
import qs from 'qs'
import {delChildren,formatTime} from '@/utils/utils'
var vm;
export default {
  props:['dicObj'],
  data() {
    return {
      isDio:false,//弹窗类型
      dioTit :'',//弹窗标题
      dioType :'',//弹窗类型
      dioWidth :'',//弹窗宽度

      //form信息
      inform :{
        id:'',
        // homeId:'',//场所
        ticketType:'',//类型
        ticketLevel:'',//级别
        handlerUser:'',//处理人
        deviceInstallList:[],//安装设备
        remark:'',//备注
        handleType:'',//处理方式
        finalResult:'',//处理结果
        alarmRespList:[],//告警详情
        alarmAnalysis:'',//告警分析及原因
        suggestion:'',//解决措施及建议
        installBeforeIcons:[],//安装前图片
        installedIcons:[],//安装后图片
        finalResultIcons:[],//处理结果图片
        alarmAnalysisIcons:[],//告警分析及原因图片
        suggestionIcons:[],//解决措施及建议图片
        installBeforeIconsShow:[],//安装前查看
        installedIconsShow:[],//安装后查看
        finalResultIconsShow:[],//处理结果查看
        alarmAnalysisIconsShow:[],//告警分析及原因查看
        suggestionIconsShow:[],//解决措施及建议查看
        num1:0,
        num2:0,
        num3:0,
        num4:0,
        num5:0,
      },
      userList:[],//处理人列表
      typeList:[],//设备类型
      typeData:[],//工单类型
      stepActive:0,

      rules :{
        // homeId: [
        //   { required: true, message: '请选择场所', trigger: 'change' },
        // ],
        ticketLevel: [
          { required: true, message: '请选择工单等级', trigger: 'change' },
        ],
        ticketType: [
          { required: true, message: '请选择工单类型', trigger: 'change' },
        ],
        handleType: [
          { required: true, message: '请选择处理方式', trigger: 'change' },
        ],
      },

      //查看上传的图片
      dialogVisible:false,
      dialogImageUrl:'',
    }
  },
  created(){
    vm = this
  },
  mounted(){
    
  },

  //方法
  methods: {
    //初始化
    init (type,item){
      // console.log(item);
      vm.dioType = type
      vm.dioWidth = '800px'
      vm.isDio = true
      if(typeof item === 'string'){
        vm.getTicketType('1')
      }else{
        vm.getTicketType('2')
      }
      
      vm.ticketGetDeviceType()
      vm.$nextTick(() => {
        if(type == 'add'){
          vm.dioTit = '创建工单'
          vm.ticketGetPerson()
        }else{
          if(type == 'edit'){
            vm.dioTit = '编辑工单'
          }else if(type == 'detail'){
            vm.dioTit = '查看工单'
          }else if(type == 'handle'){
            vm.dioTit = '处理工单'
          }else if(type == 'audit'){
            vm.dioTit = '审核工单'
          }else if(type == 'reorder'){
            vm.dioTit = '重新派单'
          }
          vm.ticketDetail(typeof item === 'string'?item:item.id)
        }
      })
    },
    //审核工单
    async ticketAudit(type){
      let dataObj = {
        'id':vm.inform.id,
        'checkStatus':type
      }
      const res = await apiUrl.ticketAudit(dataObj)
      if(res?.code==200){
        vm.$message.success('审核成功！')
        vm.$emit('addEditSucc')
        vm.closeDio('dioform')
      }else{
        vm.$message.error(res.message)
      }
    },
    //重新派单
    async ticketAgain(){
      let dataObj = {
        'id':vm.inform.id,
        'ticketLevel':vm.inform.ticketLevel,//等级
        'handlerUser':vm.inform.handlerUser,//处理人
      }
      const res = await apiUrl.ticketAgain(dataObj)
      if(res?.code==200){
        vm.$message.success('重新派单成功！')
        vm.$emit('addEditSucc')
        vm.closeDio('dioform')
      }else{
        vm.$message.error(res.message)
      }
    },
    //已处理待审核修改
    async ticketAuditModify(){
      if(vm.inform.handleType==''){
        vm.$message.error('请选择处理方式')
        return
      }
      let dataObj = new FormData();
      dataObj.append('id', vm.inform.id)
      dataObj.append('handleType', vm.inform.handleType)//处理方式
      dataObj.append('ticketLevel', vm.inform.ticketLevel)//工单等级
      dataObj.append('handlerUser', vm.inform.handlerUser)//处理人
      dataObj.append('finalResult', vm.inform.finalResult)//处理结果

      if(vm.inform.finalResultIcons.length>0){//处理结果
        for(var i in vm.inform.finalResultIcons){
          dataObj.append('finalResultIcons['+i+'].file',vm.inform.finalResultIcons[i])
        }
      }
      if(vm.inform.finalResultIconsShow.length>0){//改变后处理结果
        // 首先使用filter过滤出没有uid的元素  
        const fileArr = vm.inform.finalResultIconsShow.filter(item => !('uid' in item));
        // 然后使用map提取这些元素的name值  
        const arr3 = fileArr?.map(item => item.url);
        const newArr3 = vm.getStrUrl(arr3)
        if(arr3.length>0 && arr3.length!=this.inform.num3){
          dataObj.append('changeOriFinalResultIcons',newArr3.join(','))
        }
      }else{
        dataObj.append('changeOriFinalResultIcons','')
      }
      //判断是安装配置工单，需要把安装前和安装后的图片传过去
      if(this.inform.ticketType == '4'){
        if(vm.inform.installBeforeIcons.length>0){//安装前
          for(var i in vm.inform.installBeforeIcons){
            dataObj.append('installBeforeIcons['+i+'].file',vm.inform.installBeforeIcons[i])
          }
        }
        if(vm.inform.installedIcons.length>0){//安装后
          for(var i in vm.inform.installedIcons){
            dataObj.append('installedIcons['+i+'].file',vm.inform.installedIcons[i])
          }
        }
        if(vm.inform.installBeforeIconsShow.length>0){//改变后安装前
          // 首先使用filter过滤出没有uid的元素  
          const fileArr = vm.inform.installBeforeIconsShow.filter(item => !('uid' in item));
          // 然后使用map提取这些元素的name值  
          const arr1 = fileArr?.map(item => item.url);
          const newArr1 = vm.getStrUrl(arr1)
          if(arr1.length>0 && arr1.length!=this.inform.num1){
            dataObj.append('changeOriInstallBeforeIcons',newArr1.join(','))
          }
        }else{
          dataObj.append('changeOriInstallBeforeIcons','')
        }
        if(vm.inform.installedIconsShow.length>0){//改变后安装后
          // 首先使用filter过滤出没有uid的元素  
          const fileArr = vm.inform.installedIconsShow.filter(item => !('uid' in item));
          // 然后使用map提取这些元素的name值  
          const arr2 = fileArr?.map(item => item.url);
          const newArr2 = vm.getStrUrl(arr2)
          if(arr2.length>0 && arr2.length!=this.inform.num2){
            dataObj.append('changeOriInstalledIcons',newArr2.join(','))
          }
        }else{
          dataObj.append('changeOriInstalledIcons','')
        }
      }
      //判断是告警工单，需要把告警分析以及原因分析、解决措施及建议的图片传过去
      if(this.inform.ticketType != '4'&&this.inform.ticketType != '5'){
        dataObj.append('alarmAnalysis', vm.inform.alarmAnalysis)//告警分析以及原因分析
        dataObj.append('suggestion', vm.inform.suggestion)//解决措施及建议
        if(vm.inform.alarmAnalysisIcons.length>0){//处理结果
          for(var i in vm.inform.alarmAnalysisIcons){
            dataObj.append('finalResultIcons['+i+'].file',vm.inform.alarmAnalysisIcons[i])
          }
        }
        if(vm.inform.alarmAnalysisIconsShow.length>0){//改变后处理结果
          // 首先使用filter过滤出没有uid的元素  
          const fileArr = vm.inform.alarmAnalysisIconsShow.filter(item => !('uid' in item));
          // 然后使用map提取这些元素的name值  
          const arr4 = fileArr?.map(item => item.url);
          const newArr4 = vm.getStrUrl(arr4)
          if(arr4.length>0 && arr4.length!=this.inform.num4){
            dataObj.append('changeOriAlarmAnalysisIcons',newArr4.join(','))
          }
        }else{
          dataObj.append('changeOriAlarmAnalysisIcons','')
        }
        if(vm.inform.suggestionIcons.length>0){//处理结果
          for(var i in vm.inform.suggestionIcons){
            dataObj.append('finalResultIcons['+i+'].file',vm.inform.suggestionIcons[i])
          }
        }
        if(vm.inform.suggestionIconsShow.length>0){//改变后处理结果
          // 首先使用filter过滤出没有uid的元素  
          const fileArr = vm.inform.suggestionIconsShow.filter(item => !('uid' in item));
          // 然后使用map提取这些元素的name值  
          const arr5 = fileArr?.map(item => item.url);
          const newArr5 = vm.getStrUrl(arr5)
          if(arr5.length>0 && arr5.length!=this.inform.num5){
            dataObj.append('changeOriSuggestionIcons',newArr5.join(','))
          }
        }else{
          dataObj.append('changeOriSuggestionIcons','')
        }
      }
      const res = await apiUrl.ticketAuditModify(dataObj,100000)
      if(res?.code==200){
        vm.$message.success('修改成功！')
        vm.$emit('addEditSucc')
        vm.closeDio('dioform')
      }else{
        vm.$message.error(res.message)
      }
    },
    //处理工单
    async ticketHandle(type){
      if(vm.inform.handleType==''){
        vm.$message.error('请选择处理方式')
        return
      }
      let dataObj = new FormData();
      dataObj.append('id', vm.inform.id)
      dataObj.append('handleStatus', type)//处理状态：1处理按钮--暂存，2处理按钮--提交
      dataObj.append('handleType', vm.inform.handleType)//处理方式
      dataObj.append('ticketLevel', vm.inform.ticketLevel)//工单等级
      dataObj.append('handlerUser', vm.inform.handlerUser)//处理人
      dataObj.append('finalResult', vm.inform.finalResult)//处理结果
      
      if(vm.inform.finalResultIcons.length>0){//处理结果
        for(var i in vm.inform.finalResultIcons){
          dataObj.append('finalResultIcons['+i+'].file',vm.inform.finalResultIcons[i])
        }
      }
      if(vm.inform.finalResultIconsShow.length>0){//改变后处理结果
        // 首先使用filter过滤出没有uid的元素  
        const fileArr = vm.inform.finalResultIconsShow.filter(item => !('uid' in item));
        // 然后使用map提取这些元素的name值  
        const arr3 = fileArr?.map(item => item.url);
        const newArr3 = vm.getStrUrl(arr3)
        if(arr3.length>0 && arr3.length!=this.inform.num3){
          dataObj.append('changeOriFinalResultIcons',newArr3.join(','))
        }else if(arr3.length==0){
          dataObj.append('changeOriFinalResultIcons','')
        }
      }else{
        dataObj.append('changeOriFinalResultIcons','')
      }
      //判断是安装配置工单，需要把安装前和安装后的图片传过去
      if(this.inform.ticketType == '4'){
        if(vm.inform.installBeforeIcons.length>0){//安装前
          for(var i in vm.inform.installBeforeIcons){
            dataObj.append('installBeforeIcons['+i+'].file',vm.inform.installBeforeIcons[i])
          }
        }
        if(vm.inform.installedIcons.length>0){//安装后
          for(var i in vm.inform.installedIcons){
            dataObj.append('installedIcons['+i+'].file',vm.inform.installedIcons[i])
          }
        }
        if(vm.inform.installBeforeIconsShow.length>0){//改变后安装前
          // 首先使用filter过滤出没有uid的元素  
          const fileArr = vm.inform.installBeforeIconsShow.filter(item => !('uid' in item));
          // 然后使用map提取这些元素的name值  
          const arr1 = fileArr?.map(item => item.url);
          const newArr1 = vm.getStrUrl(arr1)
          if(arr1.length>0 && arr1.length!=this.inform.num1){
            dataObj.append('changeOriInstallBeforeIcons',newArr1.join(','))
          }else if(arr1.length==0){
            dataObj.append('changeOriInstallBeforeIcons','')
          }
        }else{
          dataObj.append('changeOriInstallBeforeIcons','')
        }
        if(vm.inform.installedIconsShow.length>0){//改变后安装后
          // 首先使用filter过滤出没有uid的元素  
          const fileArr = vm.inform.installedIconsShow.filter(item => !('uid' in item));
          // 然后使用map提取这些元素的name值  
          const arr2 = fileArr?.map(item => item.url);
          const newArr2 = vm.getStrUrl(arr2)
          if(arr2.length>0 && arr2.length!=this.inform.num2){
            dataObj.append('changeOriInstalledIcons',newArr2.join(','))
          }else if(arr2.length==0){
            dataObj.append('changeOriInstalledIcons','')
          }
        }else{
          dataObj.append('changeOriInstalledIcons','')
        }
      }
      //判断是告警工单，需要把告警分析以及原因分析、解决措施及建议的图片传过去
      if(this.inform.ticketType != '4'&&this.inform.ticketType != '5'){
        dataObj.append('alarmAnalysis', vm.inform.alarmAnalysis)//告警分析以及原因分析
        dataObj.append('suggestion', vm.inform.suggestion)//解决措施及建议
        if(vm.inform.alarmAnalysisIcons.length>0){//处理结果
          for(var i in vm.inform.alarmAnalysisIcons){
            dataObj.append('alarmAnalysisIcons['+i+'].file',vm.inform.alarmAnalysisIcons[i])
          }
        }
        if(vm.inform.alarmAnalysisIconsShow.length>0){//改变后处理结果
          // 首先使用filter过滤出没有uid的元素  
          const fileArr = vm.inform.alarmAnalysisIconsShow.filter(item => !('uid' in item));
          // 然后使用map提取这些元素的name值  
          const arr4 = fileArr?.map(item => item.url);
          const newArr4 = vm.getStrUrl(arr4)
          if(arr4.length>0 && arr4.length!=this.inform.num4){
            dataObj.append('changeOriAlarmAnalysisIcons',newArr4.join(','))
          }else if(arr4.length==0){
            dataObj.append('changeOriAlarmAnalysisIcons','')
          }
        }else{
          dataObj.append('changeOriAlarmAnalysisIcons','')
        }
        if(vm.inform.suggestionIcons.length>0){//处理结果
          for(var i in vm.inform.suggestionIcons){
            dataObj.append('suggestionIcons['+i+'].file',vm.inform.suggestionIcons[i])
          }
        }
        if(vm.inform.suggestionIconsShow.length>0){//改变后处理结果
          // 首先使用filter过滤出没有uid的元素  
          const fileArr = vm.inform.suggestionIconsShow.filter(item => !('uid' in item));
          // 然后使用map提取这些元素的name值  
          const arr5 = fileArr?.map(item => item.url);
          const newArr5 = vm.getStrUrl(arr5)
          if(arr5.length>0 && arr5.length!=this.inform.num5){
            dataObj.append('changeOriSuggestionIcons',newArr5.join(','))
          }else if(arr5.length==0){
            dataObj.append('changeOriSuggestionIcons','')
          }
        }else{
          dataObj.append('changeOriSuggestionIcons','')
        }
      }
      const res = await apiUrl.ticketHandle(dataObj,100000)
      if(res?.code==200){
        vm.$message.success('处理成功！')
        vm.$emit('addEditSucc')
        vm.closeDio('dioform')
      }else{
        vm.$message.error(res.message)
      }
    },
    //截取字符串
    getStrUrl(arr){ 
      const newArr = arr.map(url => {  
        const index = url.indexOf('/fileView');  
        if (index !== -1) {  
          // 提取从 /fileView 后的所有字符  
          return url.slice(index + '/fileView'.length);  
        } else {  
          // 如果找不到 /fileView，则返回原始 URL 或空字符串（根据你的需求）  
          return url; // 或者返回 ''  
        }  
      });
      return newArr
    },
    //获取工单详情
    async ticketDetail (id){
      const res = await apiUrl.ticketDetail(id)
      if(res?.code==200){
        let arr1 = [],arr2 = [],arr3 = [],arr4=[],arr5=[];
        if(res.data.ticketModel.installBeforeIcons){
          let arr = res.data.ticketModel.installBeforeIcons.split(',')
          for(var i in arr){
            let obj = {
              url:`${globalStr}/fileView${arr[i]}`
            }
            arr1.push(obj)
          }
        }
        if(res.data.ticketModel.installedIcons){
          let arr = res.data.ticketModel.installedIcons.split(',')
          for(var i in arr){
            let obj = {
              url:`${globalStr}/fileView${arr[i]}`
            }
            arr2.push(obj)
          }
        }
        if(res.data.ticketModel.finalResultIcons){
          let arr = res.data.ticketModel.finalResultIcons.split(',')
          for(var i in arr){
            let obj = {
              url:`${globalStr}/fileView${arr[i]}`
            }
            arr3.push(obj)
          }
        }
        if(res.data.ticketModel.alarmAnalysisIcons){
          let arr = res.data.ticketModel.alarmAnalysisIcons.split(',')
          for(var i in arr){
            let obj = {
              url:`${globalStr}/fileView${arr[i]}`
            }
            arr4.push(obj)
          }
        }
        if(res.data.ticketModel.suggestionIcons){
          let arr = res.data.ticketModel.suggestionIcons.split(',')
          for(var i in arr){
            let obj = {
              url:`${globalStr}/fileView${arr[i]}`
            }
            arr5.push(obj)
          }
        }
        vm.inform = {
          id: res.data.ticketModel.id,
          // homeId: `${res.data.ticketModel.homeId}`,//场所
          ticketType: `${res.data.ticketModel.ticketType}`,//类型
          ticketLevel: `${res.data.ticketModel.ticketLevel}`,//级别
          handlerUser: res.data.ticketModel.handlerUser? res.data.ticketModel.handlerUser:'',//处理人
          deviceInstallList: res.data.installDeviceList||[],//安装设备
          remark: res.data.ticketModel.remark||'',//备注
          handleType:res.data.ticketModel.handleType?`${res.data.ticketModel.handleType}`:'',//处理方式
          finalResult:res.data.ticketModel.finalResult||'',//处理结果
          alarmRespList:res.data.alarmRespList||[],//告警详情
          alarmAnalysis:res.data.ticketModel.alarmAnalysis||'',//告警分析及原因
          suggestion:res.data.ticketModel.suggestion||'',//解决措施及建议
          installBeforeIcons:[],//安装前
          installedIcons:[],//安装后
          finalResultIcons:[],//处理结果
          alarmAnalysisIcons:[],//告警分析及原因
          suggestionIcons:[],//解决措施及建议
          // installBeforeIcons:res.data.ticketModel.installBeforeIcons?.split(',')||[],//安装前
          // installedIcons:res.data.ticketModel.installedIcons?.split(',')||[],//安装后
          // finalResultIcons:res.data.ticketModel.finalResultIcons?.split(',')||[],//处理结果
          // alarmAnalysisIcons:res.data.ticketModel.alarmAnalysisIcons?.split(',')||[],//告警分析及原因
          // suggestionIcons:res.data.ticketModel.suggestionIcons?.split(',')||[],//解决措施及建议
          installBeforeIconsShow:arr1,//安装前查看
          installedIconsShow:arr2,//安装后查看
          finalResultIconsShow:arr3,//处理结果查看
          alarmAnalysisIconsShow:arr4,//告警分析及原因查看
          suggestionIconsShow:arr5,//解决措施及建议查看
          num1:arr1.length,
          num2:arr2.length,
          num3:arr3.length,
          num4:arr4.length,
          num5:arr5.length,
        }
        vm.mergeObjects(vm.inform,res.data.ticketModel)
        // console.log(vm.inform);
        vm.ticketGetPerson()
        vm.stepActive = res.data.ticketModel.ticketStatus == '0'? 0
                        :res.data.ticketModel.ticketStatus == '1'? 1
                        :res.data.ticketModel.ticketStatus == '2'? 2
                        :res.data.ticketModel.ticketStatus == '3'? 3
                        :res.data.ticketModel.ticketStatus == '4'||res.data.ticketModel.ticketStatus == '5'? 5:0
      }else{
        vm.$message.error(res.message)
      }
    },
    //上传格式校验
    beforeUpload (file){
      const isPNG = file.type ==='image/png' || file.type === 'image/jpg'||file.type === 'image/jpeg'
      const isLt500M = file.size / 1024 / 1024 < 500
      if (!isPNG) {
        vm.$message.warning('上传图片只能是JPG、JPEG或PNG 格式!')
      }
      if (!isLt500M) {
        vm.$message.warning('上传图片大小不能超过 500MB!')
      }
      return isPNG && isLt500M
      
    },
    // 文件提交
    async httpRequest1 (data){
      let that = this
      let rd =new FileReader()// 创建文件读取对象
      let file = data.file
      rd.readAsDataURL(file)// 文件读取装换为base64类型
      rd.onloadend =function (e) {
        that.inform.installBeforeIcons.push(data.file)
        let obj = {
          url:this.result,
          uid:data.file.uid
        }
        that.inform.installBeforeIconsShow.push(obj)
      }
    },
    // 文件提交
    async httpRequest2 (data){
      let that = this
      let rd =new FileReader()// 创建文件读取对象
      let file = data.file
      rd.readAsDataURL(file)// 文件读取装换为base64类型
      rd.onloadend =function (e) {
        that.inform.installedIcons.push(data.file)
        let obj = {
          url:this.result,
          uid:data.file.uid
        }
        that.inform.installedIconsShow.push(obj)
      }
    },
    // 文件提交
    async httpRequest3 (data){
      let that = this
      let rd =new FileReader()// 创建文件读取对象
      let file = data.file
      rd.readAsDataURL(file)// 文件读取装换为base64类型
      rd.onloadend =function (e) {
        that.inform.finalResultIcons.push(data.file)
        let obj = {
          url:this.result,
          uid:data.file.uid
        }
        that.inform.finalResultIconsShow.push(obj)
      }
    },
    // 文件提交
    async httpRequest4 (data){
      let that = this
      let rd =new FileReader()// 创建文件读取对象
      let file = data.file
      rd.readAsDataURL(file)// 文件读取装换为base64类型
      rd.onloadend =function (e) {
        that.inform.alarmAnalysisIcons.push(data.file)
        let obj = {
          url:this.result,
          uid:data.file.uid
        }
        that.inform.alarmAnalysisIconsShow.push(obj)
      }
    },
    // 文件提交
    async httpRequest5 (data){
      let that = this
      let rd =new FileReader()// 创建文件读取对象
      let file = data.file
      rd.readAsDataURL(file)// 文件读取装换为base64类型
      rd.onloadend =function (e) {
        that.inform.suggestionIcons.push(data.file)
        let obj = {
          url:this.result,
          uid:data.file.uid
        }
        that.inform.suggestionIconsShow.push(obj)
      }
    },
    //移除文件
    delIcon(index,item,type) {
      console.log('item',item.uid);
      console.log('item2',this.inform.installBeforeIcons);
      if(!item.uid){
        if(type=='1'){
          this.inform.installBeforeIconsShow.splice(index, 1);
        }else if(type=='2'){
          this.inform.installedIconsShow.splice(index, 1);
        }else if(type=='3'){
          this.inform.finalResultIconsShow.splice(index, 1);
        }else if(type=='4'){
          this.inform.alarmAnalysisIconsShow.splice(index, 1);
        }else if(type=='5'){
          this.inform.suggestionIconsShow.splice(index, 1);
        }
      }else{
        if(type=='1'){
          for(var x=0;x<this.inform.installBeforeIcons.length;x++){
            if(this.inform.installBeforeIcons[x].uid === this.inform.installBeforeIconsShow[index].uid){
              this.inform.installBeforeIcons.splice(x,1)
              x-=1
            }
          }
          this.inform.installBeforeIconsShow.splice(index, 1);
        }else if(type=='2'){
          for(var x=0;x<this.inform.installedIcons.length;x++){
            if(this.inform.installedIcons[x].uid === this.inform.installedIconsShow[index].uid){
              this.inform.installedIcons.splice(x,1)
              x-=1
            }
          }
          this.inform.installedIconsShow.splice(index, 1);
        }else if(type=='3'){
          for(var x=0;x<this.inform.finalResultIcons.length;x++){
            if(this.inform.finalResultIcons[x].uid === this.inform.finalResultIconsShow[index].uid){
              this.inform.finalResultIcons.splice(x,1)
              x-=1
            }
          }
          this.inform.finalResultIconsShow.splice(index, 1);
        }else if(type=='4'){
          for(var x=0;x<this.inform.alarmAnalysisIcons.length;x++){
            if(this.inform.alarmAnalysisIcons[x].uid === this.inform.alarmAnalysisIconsShow[index].uid){
              this.inform.alarmAnalysisIcons.splice(x,1)
              x-=1
            }
          }
          this.inform.alarmAnalysisIconsShow.splice(index, 1);
        }else if(type=='5'){
          for(var x=0;x<this.inform.suggestionIcons.length;x++){
            if(this.inform.suggestionIcons[x].uid === this.inform.suggestionIconsShow[index].uid){
              this.inform.suggestionIcons.splice(x,1)
              x-=1
            }
          }
          this.inform.suggestionIconsShow.splice(index, 1);
        }
      }
    },
    //预览图片
    iconPreview(url) {
      this.dialogImageUrl = url;
      this.dialogVisible = true;
    },
    //对象赋值
    mergeObjects(target, source) {  
      // 遍历源对象的所有可枚举属性  
      for (const key in source) {  
        // 使用hasOwnProperty来检查该属性是否是源对象自身的属性  
        // 而不是其原型链上的属性  
        if (source.hasOwnProperty(key)) {  
          // 检查目标对象是否还没有这个属性  
          // 注意：这里我们并没有使用in操作符，因为in操作符会检查原型链  
          if (!target.hasOwnProperty(key)) {  
              // 如果目标对象没有这个属性，则添加它  
              target[key] = source[key];  
          }  
          // 如果需要合并对象（即source[key]也是对象），可以递归调用mergeObjects  
          // 但这里我们只处理不覆盖已有属性的情况  
        }  
      }  
      // 返回目标对象（虽然它是通过引用传递的，但这里的返回主要是出于函数清晰性的考虑）  
      return target;  
    },
    formatTime2(time){
      return time?formatTime(time,'Y-M-D h:m:s'):'-'
    },
    //场所改变
    // homeChange(val){
    //   vm.ticketGetPerson()
    // },
    //添加item
    addItem(){
      this.inform.deviceInstallList.push({
        deviceTypeName:'',
        installNumber:''
      })
    },
    //移除item
    removeItem(index){
      this.inform.deviceInstallList.splice(index,1)
    },
    //校验form
    checkForm (formEl,type){
      vm.$refs[formEl].validate((valid) => {
        if (valid) {
          if(vm.dioType=='add'){
            vm.addTicket()
          }else if(vm.dioType=='edit'){
            //判断是已处理待审核状态
            if(vm.inform.ticketStatus=='3'){
              vm.ticketAuditModify()
            }else{
              vm.updateTicket()
            }
          }else if(vm.dioType == 'handle'){
            vm.ticketHandle(type?type:'2')
          }else if(vm.dioType == 'audit'){
            vm.ticketAudit(type?type:'1')
          }else if(vm.dioType == 'reorder'){
            vm.ticketAgain()
          }
        } else {return false}
      })
    },
    //关闭弹窗
    closeDio (formEl){
      // vm.$nextTick(() => {
        vm.$refs[formEl].resetFields()
      // })
      vm.userList = []
      vm.typeList = []
      vm.typeData = []
      vm.stepActive = 0
      // vm.inform.roleIds = []
      vm.inform = {
        id:'',
        // homeId:'',//场所
        ticketType:'',//类型
        ticketLevel:'',//级别
        handlerUser:'',//处理人
        deviceInstallList:[],//安装设备
        remark:'',//备注
        handleType:'',//处理方式
        finalResult:'',//处理结果
        alarmRespList:[],//告警详情
        alarmAnalysis:'',//告警分析及原因
        suggestion:'',//解决措施及建议
        installBeforeIcons:[],//安装前
        installedIcons:[],//安装后
        finalResultIcons:[],//处理结果
        alarmAnalysisIcons:[],//告警分析及原因
        suggestionIcons:[],//解决措施及建议
        installBeforeIconsShow:[],//安装前查看
        installedIconsShow:[],//安装后查看
        finalResultIconsShow:[],//处理结果查看
        alarmAnalysisIconsShow:[],//告警分析及原因查看
        suggestionIconsShow:[],//解决措施及建议查看
        num1:0,
        num2:0,
        num3:0,
        num4:0,
        num5:0,
      }
      vm.isDio = false
    },
    //获取处理人
    async ticketGetPerson (){
      let csType = vm.$store.state.csType
      const res = await apiUrl.ticketGetPerson(csType)
      if(res?.code==200){
        res.data?.forEach(item =>{
          if(item.personName){
            item.name = item.account+'('+item.personName+')'
          }else{
            item.name = item.account
          }
        })
        vm.userList = res.data
      }else{
        vm.$message.error(res.message)
      }
    },
    //获取设备类型
    async ticketGetDeviceType (){
      const res = await apiUrl.ticketGetDeviceType()
      if(res?.code==200){
        vm.typeList = res.data
      }else{
        vm.$message.error(res.message)
      }
    },
    //获取设备类型
    async getTicketType (type){
      // let type = '2'//1告警，2非告警
      const res = await apiUrl.getTicketType(type)
      if(res?.code==200){
        vm.typeData = res.data
      }else{
        vm.$message.error(res.message)
      }
    },
    //判断指定字段是否为空，array:检查的数组，fields:检查的字段数组['field1', 'field2']
    hasEmptyFields(array, fields) {  
      return array.some(obj => {  
        // 检查字段数组中的每个字段是否存在于对象中，并且其值是否为空字符串  
        return fields.some(field => {  
          // 如果字段在对象中不存在或者值为空字符串，返回true  
          return !obj.hasOwnProperty(field) || obj[field] === "";  
        });  
      });    
    },
    //新增
    async addTicket(){
      let dataObj = {
        // 'homeId':vm.inform.homeId,//场所
        'areaId':vm.$store.state.csType,//区域
        'ticketType':vm.inform.ticketType,//类型
        'ticketLevel':vm.inform.ticketLevel,//级别
        'handlerUser':vm.inform.handlerUser,//处理人
        'remark':vm.inform.remark,//备注
      }
      if(vm.inform.ticketType=='4'){
        if(this.inform.deviceInstallList.length>0){
          const isNot = vm.hasEmptyFields(this.inform.deviceInstallList,['deviceTypeName', 'installNumber'])
          if(isNot){
            vm.$message.warning('安装设备数据不能为空！')
            return
          }
        }
        dataObj.deviceInstallList = vm.inform.deviceInstallList
      }
      const res = await apiUrl.addTicket(dataObj)
      if(res?.code==200){
        vm.$message.success('创建成功！')
        vm.$emit('addEditSucc')
        vm.closeDio('dioform')
      }else{
        vm.$message.error(res.message)
      }
    },
    //修改
    async updateTicket(){
      let dataObj = {
        'id':vm.inform.id,
        'ticketLevel':vm.inform.ticketLevel,//级别
        'handlerUser':vm.inform.handlerUser,//处理人
      }
      const res = await apiUrl.updateTicket(dataObj)
      if(res?.code==200){
        vm.$message.success('修改成功！')
        vm.$emit('addEditSucc')
        vm.closeDio('dioform')
      }else{
        vm.$message.error(res.message)
      }
    }
  }
}
</script>
<style lang='scss'>
.ticketAddEdit{
  .contDio{
    max-height: 500px;
    overflow: auto;
    .stepDiv{
      position:relative;
      .tipsIcon{
        position: absolute;
        right:50px;
        top:20px;
        width:150px;
        height:150px;
        background: url('../../../../assets/images/pass.png');
        background-size: 100% 100%;
        &.fail{
          background: url('../../../../assets/images/nopass.png');
          background-size: 100% 100%;
        }
      }
    }
    .imgDiv{
      display: inline-flex;
      flex-wrap: wrap;
      .item{
        margin-right: 5px;
        width: 58px;
        height: 58px;
        border: 1px solid #ddd;
        border-radius: 4px;
        overflow: hidden;
        position: relative;
        margin-bottom: 5px;
        .btn{
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 100%;
          display: none;
          align-items: center;
          justify-content: space-around;
          i{
            font-size: 20px;
            cursor: pointer;
            color: #fff;
          }
        }
        &:hover{
          .btn{
            display: flex;
          }
        }
      }
    }
    .el-input.is-disabled .el-input__inner,.el-textarea.is-disabled .el-textarea__inner{
      opacity: 0.5;
    }
    .addBtn{
      .el-button--small{
        font-size:16px;
        color:#fff;
        i{
          color:#00c6ff;
          margin-right:5px;
        }
      }
    }
    .tableCont{
      th.el-table__cell{
        padding:0;
      }
      .el-button i{
        font-size: 20px;
      }
    }
  }
  .uploader{
    .el-upload-list--picture-card{
      .el-upload-list__item{
        width: 60px;
        height: 60px;
      }
    }
    .el-upload--picture-card{
      width: 60px;
      height: 60px;
      line-height: 58px;
      background:none;
      i.el-icon-plus{
        line-height:inherit;
      }
    }
  }
}
</style>